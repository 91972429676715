<template>
  <transition name="modal-animate" @after-enter="showContent = true">
    <div class="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
      v-if="value.open">
      <!-- Modal -->
      <transition appear enter-active-class="transition duration-200 ease-out"
        enter-class="transform translate-y-1/2 opacity-0" enter-to-class="opacity-100"
        leave-active-class="transition duration-200 ease-in" leave-class="opacity-100"
        leave-to-class="transform translate-y-1/2 opacity-0">
        <div
          class="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-4xl"
          role="dialog" id="modal">
          <!-- @click.away="closeModal" -->
          <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
          <header class="flex justify-end">
            <button
              class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700 focus:outline-none"
              aria-label="close" @click="closeModal">
              <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
                <path
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd" fill-rule="evenodd"></path>
              </svg>
            </button>
          </header>
          <!-- Modal body -->
          <div slot="modal_body" class="flex flex-col mt-4 mb-6 space-y-5">
            <!-- Modal title -->
            <div class="flex items-center justify-between space-x-2">
              <p class="mb-2 text-lg text-gray-700 dark:text-gray-300">
                Add order for
                <span class="font-semibold">{{ value.customer.name }}</span>
              </p>
              <p class="mb-2 font-mono text-gray-700 dark:text-gray-300">
                Pending ₹{{
                  value.customer.total_pending
                    ? value.customer.total_pending
                    : 0.0
                }}
              </p>
            </div>
            <div class="flex flex-row flex-wrap items-center justify-center space-x-2 sm:space-x-4">
              <div v-for="category in categories" :key="category.nano_id">
                <button class="px-4 py-1 mb-2 border-2 focus:outline-none rounded-xl" :class="
                  selectedCategory.nano_id === category.nano_id
                    ? 'border-indigo-800 dark:border-primary-light text-white bg-indigo-700 dark:bg-indigo-400'
                    : 'border-indigo-700 dark:border-indigo-400 text-indigo-700 dark:text-indigo-400 '
                " @click="selectedCategory = { ...category }">
                  {{ category.name }}
                </button>
              </div>
            </div>
            <div style="height:1px" class="w-full bg-gray-200 hairline dark:bg-gray-600"></div>
            <div class="overflow-y-auto h-60 sm:h-full">
              <div class="grid gap-4 sm:grid-cols-3">
                <div v-for="product in filteredProducts" :key="product.nano_id">
                  <button
                    class="flex items-center justify-between w-full h-full px-4 py-1 space-x-4 border-2 rounded-xl focus:outline-none"
                    :class="
                      selectedProduct.nano_id === product.nano_id
                        ? 'bg-indigo-700 text-white dark:bg-indigo-400 dark:text-gray-200 border-indigo-700 dark:border-primary-light'
                        : 'border-indigo-700 dark:border-indigo-400 text-indigo-700 dark:text-indigo-400'
                    " @click="selectProduct(product)">
                    <p class="font-semibold">{{ product.name }}</p>
                    <p class="font-mono font-semibold">₹{{ product.price }}</p>
                  </button>
                </div>
              </div>
            </div>
            <div class="right-0" v-if="productSelected">
              <div style="height:1px" class="w-full mb-2 bg-gray-200 hairline dark:bg-gray-600"></div>
              <div class="flex flex-col items-end">
                <label>
                  <span class="label">
                    Enter amount paid (leave empty if unpaid)
                  </span>
                  <input type="text" class="max-w-xs input" v-model="amount" />
                </label>
              </div>
            </div>
          </div>
          <footer
            class="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50 dark:bg-gray-800">
            <button @click="closeModal"
              class="w-full px-5 py-3 text-sm font-medium leading-5 text-gray-700 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray">
              Cancel
            </button>
            <button
              class="w-full px-5 py-3 text-sm font-medium text-white rounded-lg sm:w-auto sm:px-4 sm:py-2 focus:outline-none "
              :class="
                validOrder
                  ? 'dark:bg-primary-light bg-indigo-600'
                  : 'dark:bg-gray-500 bg-gray-400'
              " @click="addOrder">
              Add order
            </button>
          </footer>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import isEmpty from "ramda/es/isEmpty";
import { numeric, maxValue } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  mixins: [validationMixin],
  name: "OrderModal",
  data() {
    return {
      amount: "",
      selectedProduct: {},
      selectedCategory: {},
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      products: ["product/getProducts"],
      categories: ["category/getCategories"],
    }),
    productSelected() {
      return !isEmpty(this.selectedProduct);
    },
    filteredProducts() {
      if (isEmpty(this.selectedCategory)) {
        return [...this.products];
      } else {
        return this.products.filter(
          (p) => { console.log(p); return p.category.id === this.selectedCategory.id }
        );
      }
    },
    validOrder() {
      if (!isEmpty(this.selectedProduct)) {
        return true;
      }
      return false;
    },
  },
  props: {
    value: {
      required: true,
      type: Object,
    },
  },
  validations() {
    return {
      amount: {
        numeric,
        maxValue: maxValue(
          !isEmpty(this.selectedProduct) ? this.selectedProduct.price : null
        ),
      },
    };
  },
  mounted() {
    this.selectedCategory = this.categories[0];
  },
  methods: {
    closeModal() {
      this.value.open = false;
      this.value.customer = {};
    },
    addOrder() {
      if (!isEmpty(this.selectedProduct)) {
        this.$store
          .dispatch("customer/placeOrder", {
            product_id: this.selectedProduct.id,
            customer_id: this.value.customer.id,
            paid: this.amount,
          })
          .then((res) => (res.success ? this.closeModal() : null));
      }
    },
    selectProduct(product) {
      this.selectedProduct = { ...product };
    },
  },
};
</script>

<style>

</style>
