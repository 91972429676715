<template>
  <div class="w-full overflow-hidden">
    <div class="flex flex-col items-center my-5 space-y-4 lg:space-y-0 lg:flex-row lg:space-x-5">
      <div>
        <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-200">
          Customers list
        </h2>
      </div>
      <div>
        <router-link
          class="px-2 py-1 text-indigo-700 border border-indigo-700 dark:text-indigo-400 rounded-xl dark:border-indigo-400"
          to="/customers/create">
          Create new customer
        </router-link>
      </div>
      <div class="w-full md:w-3/4 lg:w-1/2 2xl:w-1/4">
        <div class="relative">
          <button
            class="absolute inset-y-0 left-0 px-2 text-white transition-colors duration-150 bg-indigo-600 border border-transparent rounded-l-xl hover:bg-indigo-700 focus:outline-none dark:bg-indigo-500 "
            @click="
  loadCustomers();
search = '';
            ">
            Reset
          </button>
          <input class="h-8 pl-16 pr-20 text-sm input" placeholder="Search customer by name"
            @keyup.enter="searchCustomer()" v-model="search" />
          <button
            class="absolute inset-y-0 right-0 px-4 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-indigo-600 border border-transparent rounded-r-xl hover:bg-indigo-700 dark:bg-indigo-500 focus:outline-none"
            @click="searchCustomer()">
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="overflow-hidden border border-gray-300 rounded-xl dark:border-gray-600">
      <div class="w-full overflow-x-auto">
        <table class="w-full whitespace-no-wrap">
          <thead>
            <tr
              class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase bg-gray-300 border-b dark:border-gray-700 dark:text-gray-400 dark:bg-gray-700">
              <th class="px-4 py-3">Name</th>
              <th class="px-4 py-3">Mobile</th>
              <th class="px-4 py-3">Pending</th>
              <th class="px-4 py-3 text-center">Actions</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800" v-if="customers.length > 0">
            <tr v-for="customer in customers" :key="customer.nano_id" class="text-gray-700 dark:text-gray-400">
              <td class="px-4 py-3">
                <div class="flex items-center space-x-2">
                  <p class="font-semibold">{{ customer.name }}</p>
                  <div :class="
                    customer.verified
                      ? 'text-indigo-600 dark:text-indigo-500'
                      : 'text-gray-500 dark:text-gray-400'
                  ">
                    <svg v-if="customer.verified" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" class="w-6 h-6">
                      <path fill-rule="evenodd"
                        d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clip-rule="evenodd" />
                    </svg>
                    <svg v-else class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                      stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                </div>
              </td>
              <td class="px-4 py-3">
                <div class="flex items-center">
                  <p class="font-mono text-sm font-medium">
                    {{ customer.mobile }}
                  </p>
                </div>
              </td>
              <td class="px-4 py-3 font-mono text-sm" v-if="customer.total_pending">
                ₹{{ customer.total_pending }}
              </td>
              <td class="px-4 py-3 font-mono text-sm" v-else>₹0.00</td>

              <td class="px-4 py-3">
                <div class="flex flex-col items-center justify-center" :class="
                  customer.total_pending
                    ? 'divide-y divide-indigo-400 dark:divide-gray-400'
                    : ''
                ">
                  <div class="flex items-center space-x-4 text-sm">
                    <div>
                      <router-link
                        class="flex items-center justify-between px-2 py-1 text-sm font-medium text-indigo-600 rounded-full dark:text-gray-400 focus:outline-none "
                        aria-label="details" :to="{
                          name: 'customerdetail',
                          params: { nano_id: customer.nano_id },
                        }">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          class="w-5 h-5">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      </router-link>
                    </div>
                    <div>
                      <button
                        class="flex items-center justify-between px-2 py-1 text-sm font-medium text-indigo-600 rounded-full dark:text-gray-400 focus:outline-none "
                        aria-label="addOrder" @click="openOrderSelection(customer)">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          class="w-5 h-5">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                    </div>
                    <div v-if="user.level < 10">
                      <button
                        class="flex items-center justify-between px-2 py-1 text-sm font-medium text-indigo-600 rounded-full dark:text-gray-400 focus:outline-none "
                        aria-label="addToWaitlist" @click="addToWaitlist(customer)">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                          stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </button>
                    </div>
                    <div>
                      <router-link
                        class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-indigo-600 rounded-lg dark:text-gray-400 focus:outline-none "
                        aria-label="Edit" :to="{
                          name: 'editcustomers',
                          params: { nano_id: customer.nano_id },
                        }">
                        <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z">
                          </path>
                        </svg>
                      </router-link>
                    </div>
                    <div>
                      <button v-if="user.level > 10"
                        class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-indigo-600 rounded-lg dark:text-gray-400 focus:outline-none "
                        aria-label="Delete" @click="deletecustomer(customer.nano_id)">
                        <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd"
                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                            clip-rule="evenodd"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div v-if="customer.total_pending > 0.0"
                    class="flex items-center justify-center pt-2 space-x-2 text-sm">
                    <button
                      class="flex items-center w-40 px-2 py-1 space-x-2 text-sm font-medium text-green-600 border border-green-600 rounded-full dark:text-green-400 focus:outline-none dark:border-green-400"
                      aria-label="clear pending" @click="openCustomerPaymentModal(customer)">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </span>
                      <span>Make Payment</span>
                    </button>
                    <button
                      class="flex items-center px-2 py-1 space-x-2 text-sm font-medium text-red-600 border border-red-600 rounded-full w-36 dark:text-red-400 focus:outline-none dark:border-red-400"
                      aria-label="clear pending" @click="openClearCustomerPaymentModal(customer)">
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                          class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                      </div>
                      <div>
                        Clear Pending
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="grid px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase bg-gray-300 border-t dark:border-gray-700 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-700">
        <span class="flex items-center col-span-3">
          Showing
          {{
            pagination.meta
              ? `${pagination.meta.from}-${pagination.meta.to} of ${pagination.meta.total}`
              : null
          }}
        </span>
        <span class="col-span-2"></span>
        <!-- Pagination -->
        <span class="flex col-span-4 mt-2 sm:mt-auto sm:justify-end">
          <nav aria-label="Table navigation">
            <ul class="inline-flex items-center">
              <li>
                <button :class="
                  pagination.links && pagination.links.prev
                    ? 'text-gray-600 dark:text-gray-400'
                    : 'text-gray-400 dark:text-gray-500'
                " :disabled="pagination.links && !pagination.links.prev" class="px-2 py-1 rounded-md rounded-l-lg focus:outline-none"
                  aria-label="Previous" @click="loadCustomers(pagination.meta.current_page - 1)">
                  <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                    <path
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd" fill-rule="evenodd"></path>
                  </svg>
                </button>
              </li>
              <li>
                <button :class="
                  pagination.links && pagination.links.next
                    ? 'text-gray-600 dark:text-gray-400'
                    : 'text-gray-400 dark:text-gray-500'
                " :disabled="pagination.links && !pagination.links.next"
                  class="px-3 py-1 rounded-md rounded-r-lg focus:outline-none focus:shadow-outline-indigo"
                  aria-label="Next" @click="loadCustomers(pagination.meta.current_page + 1)">
                  <svg class="w-4 h-4 fill-current" aria-hidden="true" viewBox="0 0 20 20">
                    <path
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd" fill-rule="evenodd"></path>
                  </svg>
                </button>
              </li>
            </ul>
          </nav>
        </span>
      </div>
    </div>
    <payment-modal v-if="customerPayment.open" v-model="customerPayment">
    </payment-modal>
    <clear-payment-modal v-if="clearCustomerPayment.open" v-model="clearCustomerPayment">
    </clear-payment-modal>
    <order-modal v-if="orderSelection.open" v-model="orderSelection">
    </order-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import isEmpty from "ramda/es/isEmpty";
import PaymentModal from "./PaymentModal";
import ClearPaymentModal from "./ClearPendingModal";
import OrderModal from "./OrderModal";
export default {
  name: "CustomersComponent",
  data() {
    return {
      search: "",
      customerPayment: {
        open: false,
        customer: {},
      },
      clearCustomerPayment: {
        open: false,
        customer: {},
      },
      orderSelection: {
        open: false,
        customer: {},
      },
    };
  },
  components: {
    PaymentModal,
    ClearPaymentModal,
    OrderModal,
  },
  mounted() {
    if (isEmpty(this.customers)) {
      this.loadCustomers();
    }
    if (isEmpty(this.products)) {
      this.loadProducts();
    }
    if (isEmpty(this.categories)) {
      this.loadCategories();
    }
  },
  computed: {
    ...mapGetters({
      customers: ["customer/getCustomers"],
      user: ["auth/getAuthUser"],
      products: ["product/getProducts"],
      categories: ["category/getCategories"],
      pagination: ["customer/getPagination"],
    }),
  },
  methods: {
    searchCustomer() {
      if (this.search.length > 2) {
        this.$store.dispatch("customer/getCustomers", { search: this.search });
      }
    },
    loadCustomers(page = null) {
      this.$store.dispatch("customer/getCustomers", { page, search: this.search });
    },
    loadProducts() {
      this.$store.dispatch("product/getProducts");
    },
    loadCategories() {
      this.$store.dispatch("category/getCategories");
    },
    deletecustomer(nano_id) {
      this.$store.dispatch("customer/deleteCustomer", { nano_id });
    },
    addToWaitlist(customer) {
      this.$store.dispatch("waitlist/addToWaitlist", {
        customer_id: customer.id,
      });
    },
    openCustomerPaymentModal(customer) {
      this.customerPayment.customer = { ...customer };
      this.customerPayment.open = true;
    },
    openClearCustomerPaymentModal(customer) {
      this.clearCustomerPayment.customer = { ...customer };
      this.clearCustomerPayment.open = true;
    },
    openOrderSelection(customer) {
      this.orderSelection.customer = { ...customer };
      this.orderSelection.open = true;
    },
  },
  filters: {
    humanDate(date) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      const today = new Date(date);
      return today.toLocaleDateString("en-IN", options);
    },
  },
};
</script>

<style>

</style>
